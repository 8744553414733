.App-bg { 
  background-color: #282c34;
  min-height: auto;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 6.5vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: rgb(39, 48, 80);
}

.App-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(6px + 2vmin);
  color: white;
  background-color: rgb(39, 48, 80);
}

.App-line {
  display: block;
  height: 1.1em;
  font-size: calc(5px + 2vmin);
  color: white;
  line-height: 0em;
}

.text {
  display: inline-block;
}

.DataDiri-tab {
  display: inline-block;
  position: absolute;
  left: 9em;
}

.right-text {
  display: inline-block;
  position: absolute;
  right: 0em;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
